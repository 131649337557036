import React from "react";
import { envConfig } from "../../config";
import { featureflagService } from "../../resource/APIServices";
import '../../styles/signin.scss';

export const signInHelp=()=>{
 const sourceUrl = decodeURIComponent(window.location.href);
 if (sourceUrl.includes("fromURI") || sessionStorage.getItem("whereItCameFrom") ==="vantus") return envConfig.HELP_VANTUS;
else return envConfig.HELP_OE;
}

export const getSpiningLoader = () => {
 return (
     <div className='page-container'>
         <span className="spinner-loader-big"></span>
     </div>
 );
};

export const fetchFeatureFlag = async () => {
 try {
   const response = await featureflagService();
   const releaseFlag = response?.release_management?.releases?.PRV_1_1;
   return releaseFlag ?? false;
 } catch (error) {

   console.error('Failed to fetch feature flag:', error);
   
   return false;
 }
};


