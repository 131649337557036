const env = window && window.__env;

const envConfig = {
    FIRST_VAR: env.FIRST_VAR,
    OKTA_CLIENT_ID: env.OKTA_CLIENT_ID,
    OKTA_ISSUER: env.OKTA_ISSUER,
    OKTA_BASE_URL: env.OKTA_BASE_URL,
    COEXIST_ENDPOINT: env.COEXIST_ENDPOINT,
    ACUTE_GRP: env.ACUTE_GRP,
    RI_GRP: env.RI_GRP,
    RC_GRP: env.RC_GRP,
    SPOL_GRP: env.SPOL_GRP,
    EXCEPTION_GRP: env.EXCEPTION_GRP,
    EXCEPTION_GRP_VANTUSHQ: env.EXCEPTION_GRP_VANTUSHQ,
    EXCEPTION_GRP_VANTUSHQ_FEDERATION: env.EXCEPTION_GRP_VANTUSHQ_FEDERATION,
    WEB_ORD_GRP: env.WEB_ORD_GRP,
    REPORT_GRP: env.REPORT_GRP,
    INTFCADM_GRP: env.INTFCADM_GRP,
    ACUTE_TARGET_URL: env.ACUTE_TARGET_URL,
    RI_TARGET_URL: env.RI_TARGET_URL,
    RC_TARGET_URL: env.RC_TARGET_URL,
    SPOL_TARGET_URL: env.SPOL_TARGET_URL,
    REPORT_TARGET_URL: env.REPORT_TARGET_URL,
    REPORT_CENTER_URL: env.REPORT_CENTER_URL,
    INTFCADM_TARGET_URL: env.INTFCADM_TARGET_URL,
    ACUTE_ADDT_TARGET_URL: env.ACUTE_ADDT_TARGET_URL,
    RI_ADDT_TARGET_URL: env.RI_ADDT_TARGET_URL,
    RC_ADDT_TARGET_URL: env.RC_ADDT_TARGET_URL,
    SPOL_ADDT_TARGET_URL: env.SPOL_ADDT_TARGET_URL,
    OAUTH_TOKEN_URL: env.OAUTH_TOKEN_URL,
    OKTA_OAUTH_TOKEN_URL: env.OAUTH_TOKEN_URL,
    OAUTH_BASIC_AUTH: env.OAUTH_BASIC_AUTH,
    CUSTOM_EMAIL_ENDPOINT: env.CUSTOM_EMAIL_ENDPOINT,
    CUSTOM_EMAIL_ENDPOINT_1_1: env.CUSTOM_EMAIL_ENDPOINT_1_1,
    CUSTOM_EMAIL_API_KEY: env.CUSTOM_EMAIL_API_KEY,
    USER_INFO_ENDPOINT:env.USER_INFO_ENDPOINT,
    GET_FACTORS_ENROLLED:env.GET_FACTORS_ENROLLED,
    COUNTRIES_ALLOWED: env.COUNTRIES_ALLOWED,
    SSO_URL: env.SSO_URL,
    EMPLOYEE_SSO_URL: env.EMPLOYEE_SSO_URL,
    //for pilot
    DAYS_BEFORE_DEADLINE: env.DAYS_BEFORE_DEADLINE,
    PILOT_COOKIE: env.PILOT_COOKIE,
    ACTIVATION_URL: env.ACTIVATION_URL,
    ALLOW_ENROLL: env.ALLOW_ENROLL,
    COOKIE_DOMAIN: env.COOKIE_DOMAIN,
    URL_REGEX_PATTERN: env.URL_REGEX_PATTERN,
    ENROLL_DEADLINE_DATE: env.ENROLL_DEADLINE_DATE,
    COOKIE_MAXAGE_DATE: env.COOKIE_MAXAGE_DATE,
    PILOT_LAUNCH_DAY_DATE: env.PILOT_LAUNCH_DAY_DATE,
    NEW_RI_CORE_COMMERCE_GRP: env.NEW_RI_CORE_COMMERCE_GRP,
    NEW_RI_CORE_COMMERCE_TARGET_URL : env.NEW_RI_CORE_COMMERCE_TARGET_URL,
    NEW_ADDT_RI_CORE_COMMERCE_URL: env.NEW_ADDT_RI_CORE_COMMERCE_URL,
    PLACE_HOLDER : env.PLACE_HOLDER,
    HELP_OE: env.HELP_OE,
    HELP_VANTUS: env.HELP_VANTUS,
    APIGEE_BASE_URL: env.APIGEE_BASE_URL,
};

const resetPwd = {hasTriggered: false};
const setPwd = {hasTriggered: false};
const globalValue = {userId: null};
const globalFactorId = {enrollmentId : null}
export {envConfig, resetPwd, setPwd, globalValue, globalFactorId};